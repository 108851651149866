<template>
  <div>
    <div v-if="participant" class="eh-participant-component">
      <fp-prescription-list
        :participantId="participant.UserId"
        sortBy="PrescriptionId"
        :showPatientData="true"
      />
    </div>
    <div style="text-align: center; margin: 20px" v-else>
      <b-spinner />
    </div>
  </div>
</template>
<script>
export default {
  name: "Recipes",
  props: {
    participant: Object,
  },
};
</script>
